import React from "react";
import Authenticate from "../component/authentication/Authenticate";

export default function Login() {
    return (
        <>
            <Authenticate/>
        </>
    )
}
